import React, {useEffect, useRef, useState} from "react"
import QRCodeStyling from "qr-code-styling"
import {Box} from "@mui/material"
import {mobileUtils} from '../../utils/mobileUtils';

const qrCode = new QRCodeStyling({
  // width: 320,
  // height: 320,
  // image: '/sber-logo.svg',
  // margin: 2,
  qrOptions: {
    typeNumber: 0,
    mode: 'Byte',
    errorCorrectionLevel: 'Q'
  },
  // imageOptions: {
  //   hideBackgroundDots: true,
  //   imageSize: 0.4,
  //   margin: 16,
  //   crossOrigin: 'anonymous',
  // },
  dotsOptions: {
    color: '#000',
    type: 'square' // square
  },
  backgroundOptions: {
    color: '#FFF',
  },
  cornersSquareOptions: {
    color: '#000',
    type: 'extra-rounded',
  },
  cornersDotOptions: {
    color: '#000',
    type: 'square',
  },
})

export function QRCode(props) {
  const [mobile] = useState(mobileUtils.isMobile())
  const qrRef = useRef(null)

  useEffect(() => {
    if (qrRef.current) {
      qrCode.append(qrRef.current)
    }
  }, [])

  useEffect(() => {
    qrCode.update({
      data: props.data,
      margin: 0,
      image: props.icon ? `/images/${props.icon}.svg` : undefined,
    });
  }, [props.data]);

  useEffect(() => {
    qrCode.update({
      width: props.width * 4 ?? (mobile ? 320 : 300),
      height: props.height * 4 ?? (mobile ? 320 : 300),
      // margin: 0,
      // image: props.icon ? `/images/${props.icon}.svg` : undefined,
    });
  }, [mobile]);

  return (
    <Box
      style={{
        width: props.width,
        height: props.height,
        scale: '0.25'
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      ref={qrRef}
    />
  )
}

let z = {
  "payment_widget_data": {
    "display_type": "tpay"
  }
}

let t = {
  "payment_widget_data": {
    "display_type": "tpay",
  }
}
