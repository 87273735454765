import {Provider} from 'react-redux'
import {dispatch, store} from './store/store'
import {formSlice} from './store/form/formSlice'
import {ThemeProvider} from '@mui/material'
import "./locale/i18n"
import {appThemes} from './themes/themes'

const themeName = process.env.REACT_APP_THEME ?? 'default'

const theme = appThemes[themeName].theme
const FormPage = appThemes[themeName].FormPage

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <FormPage/>
        </ThemeProvider>
      </Provider>
    </div>
  )
}

dispatch(formSlice.actions.clearOldCache())
dispatch(formSlice.actions.restoreFromCache())

export default App;
