export const uz = {
    common: {
      title: {
        phone: 'Tarjima qiling',
        card: 'Karta raqami bo‘yicha o‘tkazma',
        sbp: 'SBP orqali pul o‘tkazmasini amalga oshiring',
        sberpay: 'sberpay orqali pul o‘tkazmasini amalga oshiring',
        walletm10: 'Tarjima qiling',
        local_banks: 'Tarjima qiling',
        account: '',
      },
      copied: 'Ko‘chirildi',
    },
    PageHeader: {
      order: 'Buyurtma {{order}}',
    },
    BankList: {
      selectBank: 'To‘lov usulini tanlang',
      bankSelectionNotice: 'Bankni tanlashda ehtiyot bo‘ling. Siz bankni qayta tanlay olmaysiz.',
    },
    CardForm: {
      amountChangedTitle: 'Dastlabki miqdor haqida ma‘lumot vaqtincha mavjud emas. Sizga to‘lashni taklif qilamiz',
      amountChangedText: '{{amount}} {{currency}}',
      amountChangedConfirm: 'Davom eting',
      sbpPhone: 'Qabul qiluvchining raqami',
      sbpBank: 'Qabul qiluvchining banki',
      sbpFio: 'Qabul qiluvchining ismi',
      remainingTime: 'Tarjima vaqti:',
      pan: 'Karta raqami',
      phone: 'Telefon raqami',
      accountNumber: 'Hisob raqami',
      recipient: 'Qabul qiluvchi',
      uploadTransactionReceipt: 'Tranzaksiya kvitansiyasini yuklang',
      attention: 'Ko‘rsatmalar',
      paymentConfirmed: 'To‘lov holatini tekshiring',
      cancel: 'Bekor qilish',
      selectYourMobileDevice: 'Qurilmangizni tanlang',
      paymentTypes: {
        card: 'Karta raqamingizdan foydalanib pul o‘tkazmasini amalga oshiring',
        phone: 'Telefon raqami orqali pul o\'tkazmasini amalga oshiring',
        sbp: '',
        walletm10: 'M10 orqali telefon raqami orqali pul o‘tkazmasini amalga oshiring',
        local_banks: 'Hisob raqamingizdan foydalanib pul o‘tkazmasini amalga oshiring',
        account: 'Hisob raqamingizdan foydalanib pul o‘tkazmasini amalga oshiring',
      },
      paymentTips: {
        doNotChangeTransferAmount: 'O‘tkazma summasini o‘zgartirmang',
        doNotSpecifyTransferComment: 'Tarjimaga izoh qo‘shmang',
        ensureRecipientNameAndBankMatchDetails: 'Qabul qiluvchining ismi va banki berilgan ma‘lumotlarga mos kelishiga ishonch hosil qiling',
        paymentMustBeMadeInOneAmount: 'To‘lov bir miqdorda amalga oshirilishi kerak',
        pressCancelToSelectAnotherBank: 'Boshqa bankni tanlash uchun "Bekor qilish" tugmasini bosing va to‘lovni qaytadan yarating.',
        transferToAccountNumberAbove: 'Yuqorida ko‘rsatilgan hisob raqamiga pul o‘tkazmasini amalga oshiring',
        ifUnableToPayPressCancel: 'Agar siz to‘lay olmasangiz, "Bekor qilish" tugmasini bosing',
        enterPaymentAmountAndSubmit: 'Ilovada to‘ldirishda ko‘rsatgan to‘lov miqdorini ko‘rsating va "Yuborish" tugmasini bosing.',
        card1: 'Qat‘iy belgilangan miqdorni o‘tkazing',
        card2: 'Pul o‘tkazmasini amalga oshirayotganda, karta raqami yuqorida ko‘rsatilgan raqamga mos kelishini tekshiring',
        card3: 'Pul o‘tkazmasidan so‘ng, to‘lov holatini tekshiring',
        card4: 'Agar siz to‘lay olmasangiz, "Bekor qilish" tugmasini bosing',
        sberpay1: 'Sberbank ilovasini oching',
        sberpay2: 'QR kodini skanerlang',
        sberpay3: 'Ilovada to‘lovni amalga oshirayotganda aniq miqdorni ko‘rsating',
        sberpay4: 'Pul o‘tkazmasidan so‘ng, to‘lov holatini tekshiring',
        sberpay5: 'Agar siz to‘lay olmasangiz, "Bekor qilish" tugmasini bosing',
        sberpayMobile1: 'Qat‘iy belgilangan miqdorni o‘tkazing',
        sberpayMobile2: 'Pul o‘tkazmasidan so‘ng, to‘lov holatini tekshiring',
        sberpayMobile3: 'Agar siz to‘lay olmasangiz, "Bekor qilish" tugmasini bosing',
        sbp1: 'Qat‘iy belgilangan miqdorni o‘tkazing',
        sbp2: 'Pul o‘tkazmasini amalga oshirayotganda, oluvchining ismi va banki yuqorida ko‘rsatilganlarga mos kelishini tekshiring',
        sbp3: 'Pul o‘tkazmasidan so‘ng, to‘lov holatini tekshiring',
        sbp4: 'Agar siz to‘lay olmasangiz, "Bekor qilish" tugmasini bosing',
      },
    },
    CashDeliveryForm: {
      enterCourierData: 'Kuryer tafsilotlarini kiriting',
      saveConfirmationCode: 'Tasdiqlash kodingizni saqlang',
      confirmationRequiredForCourier: 'Bu kurer bilan uchrashganda talab qilinadi',
      confirmationCodePrompt: 'Tasdiq kodi:',
      attentionWarning: 'Ehtiyot bo‘ling!',
      courierContactUsageNotice: 'Belgilangan ma‘lumotlar kurer tomonidan siz bilan bog‘lanish uchun ishlatiladi',
      orderAcceptedMessage: 'Rahmat, buyurtmangiz qabul qilindi.',
      courierConfirmationMessage: 'Tasdiqlash uchun kurerdan xabarni kuting.',
      sendCourier: 'Kuryer yuboring',
      label: {
        messenger: 'Messenger',
        phone: 'Telefon raqami',
        login: 'messengerga kiring/telefon raqami',
        address: 'Kuryer bilan uchrashish uchun manzil',
        immediateDelivery: 'Darhol yetkazib bering',
        deliveryDateTime: 'Etkazib berish sanasi va vaqti',
      },
      error: {
        required: 'Majburiy maydon',
        incorrectNumber: 'Noto‘g‘ri raqam',
        incorrectLogin: 'Noto‘g‘ri login',
      },
    },
    StatusBlock: {
      paymentSuccessful: 'Transfer qabul qilindi',
      paymentDeclined: 'Tarjima topilmadi',
      returnToShop: 'Saytga qaytish',
      status: {
        somethingWentWrong: 'Nimadir xato ketdi.',
        waitingForFunds: 'Biz mablag‘ kelishini kutmoqdamiz.',
        operationCancelled: 'Operatsiya bekor qilindi.',
        paymentTimeExpired: 'To‘lov muddati tugadi.',
      },
      return: {
        returnToShopAndRetryPayment: 'Siz saytga qaytib, to‘lovni takrorlashingiz mumkin.',
        returnToShop: 'Saytga qaytishingiz mumkin.',
      },
    },
    Upload: {
      ok: 'KELISHDIKMI',
      upload: 'Yuklab oling',
      error: 'Xato',
    },
    FatalError: {
      somethingWentWrong: 'Nimadir xato ketdi.',
      returnToShopAndRetryPayment: 'Siz saytga qaytib, to‘lovni takrorlashingiz mumkin.',
      returnToShop: 'Saytga qaytish',
      returnToPaymentMethodSelection: 'To‘lov usulini tanlashga qayting',
      cancel: 'Bekor qilish',
      code: {
        NO_TERMINALS_AVAILABLE: 'Bu toʻlov usuli uchun terminallar mavjud emas.',
        FORM_PAY_ALREADY_SUBMITED: 'Qayta toʻlash uchun doʻkonga qayting.',
        COMMON_ERROR: 'Toʻlov paytida xato.',
        DUPLICATE_ORDER_ERROR: 'Bu raqamga ega buyurtma allaqachon mavjud.',
        ROUTE_ERROR: 'Ushbu toʻlovni amalga oshirish imkoniyati yoʻq.',
      },
    }
  }
