import {deepLinksTypes} from './deepLinks';

const paymentTypes = {
  card: {
    key: 'card',
    type: 'card',
    displayType: 'card',
    helpTexts: [
      'card1',
      'card2',
      'card3',
      'card4',
    ],
    sberpay: {
      Android: {
        url: 'intent://ru.sberbankmobile/payments/p2p?type=card_number&requisiteNumber={replace}',
        type: 'account_number',
      },
      iOS: {
        url: 'https://www.sberbank.com/sms/pbpn?requisiteNumber={replace}',
        type: 'phone',
      },
    },
  },
  sbp: {
    key: 'sbp',
    type: 'sbp',
    displayType: 'sbp',
    helpTexts: [
      'sbp1',
      'sbp2',
      'sbp3',
      'sbp4',
    ],
    sberpay: {
      Android: {
        url: 'intent://ru.sberbankmobile/payments/p2p?type=card_number&requisiteNumber={replace}',
        type: 'account_number',
      },
      iOS: {
        url: 'https://www.sberbank.com/sms/pbpn?requisiteNumber={replace}',
        type: 'phone',
      },
    }
  },
  $sberpay: {
    isDeepLink: true,
    deepLinkType: 'sberpay',
    displayTypeTitleModified: 'sberpay',
    helpTexts: [
      'sberpay1',
      'sberpay2',
      'sberpay3',
      'sberpay4',
      'sberpay5',
    ],
    helperTextsMobile: [
      'sberpayMobile1',
      'sberpayMobile2',
      'sberpayMobile3',
    ],
  },
  $tpay: {
    isDeepLink: true,
    deepLinkType: 'tpay',
    displayTypeTitleModified: 'tpay',
  },
  walletm10: {
    key: 'WalletM10',
    type: 'phone',
    beneficiary: 'phone',
    displayType: 'phone',
    helpTexts: [
      'doNotChangeTransferAmount',
      'doNotSpecifyTransferComment',
      'ensureRecipientNameAndBankMatchDetails',
      'paymentMustBeMadeInOneAmount',
      'pressCancelToSelectAnotherBank',
    ],
  },
  local_banks: {
    key: 'local_banks',
    type: 'account',
    beneficiary: 'account',
    helpTexts: [
      'doNotChangeTransferAmount',
      'doNotSpecifyTransferComment',
      'transferToAccountNumberAbove',
      'paymentMustBeMadeInOneAmount',
      'pressCancelToSelectAnotherBank',
    ],
  },
  account: {
    key: 'account',
    type: 'account',
    beneficiary: 'account',
    displayType: 'account',
    helpTexts: [
      'doNotChangeTransferAmount',
      'doNotSpecifyTransferComment',
      'transferToAccountNumberAbove',
      'paymentMustBeMadeInOneAmount',
      'pressCancelToSelectAnotherBank',
    ],
    sberpay: {
      Android: {
        url: 'intent://ru.sberbankmobile/payments/p2p?type=card_number&requisiteNumber={replace}',
        type: 'account_number',
      },
      iOS: {
        url: 'https://www.sberbank.com/sms/pbpn?requisiteNumber={replace}',
        type: 'phone',
      },
    }
  },
  phone: {
    key: 'phone',
    type: 'phone',
    beneficiary: 'phone',
    displayType: 'phone',
    helpTexts: [
      'doNotChangeTransferAmount',
      'doNotSpecifyTransferComment',
      'ensureRecipientNameAndBankMatchDetails',
      'paymentMustBeMadeInOneAmount',
      'pressCancelToSelectAnotherBank',
    ],
  }
}

export function getRequisiteType(beneficiary, params = {}, form) {
  if(!beneficiary) {
    return {}
  }

  let found = paymentTypes[beneficiary.requisite_type.toString().toLowerCase()]

  if (deepLinksTypes.includes(form.paymentWidgetData.display_type)) {
    found = {
      ...found,
      ...paymentTypes[`$${form.paymentWidgetData.display_type}`]
    }
  }

  if (found) {
    return found
  }
}

export function fixRequisiteType(beneficiary) {
  if (beneficiary.requisite_type) {
    const paymentType = paymentTypes[beneficiary.requisite_type.toString().toLowerCase()]
    if (paymentType) {
      return paymentType.key
    }
  }
  if (!beneficiary.requisite_type) {
    if (beneficiary.phone) {
      return 'sbp'
    }
    if (beneficiary.pan) {
      return 'card'
    }
    if (beneficiary.account_number) {
      return 'local_banks'
    }
  }
}
