export const en = {
  common: {
    title: {
      phone: 'Make transfer',
      card: 'Make transfer via card',
      sbp: 'Make transder via SBP',
      sberpay: 'Make transfer via sberpay',
      walletm10: 'Make transfer',
      local_banks: 'Make transfer',
      account: '',
    },
    copied: 'Copied',
  },
  PageHeader: {
    order: 'Order {{order}}',
  },
  BankList: {
    selectBank: 'Select bank',
    bankSelectionNotice: 'Be careful with your bank selection. You cannot choose a bank again.',
  },
  CardForm: {
    amountChangedTitle: 'Operation amount has changed!',
    amountChangedText: '{{amount}} {{currency}}',
    amountChangedConfirm: 'Next',
    sbpPhone: 'SBP benificiary phone number',
    sbpBank: 'SBP benificiary bank name',
    sbpFio: 'SBP benificiary name',
    remainingTime: 'Remaining time:',
    pan: 'Card number',
    phone: 'Phone number',
    accountNumber: 'Account number',
    recipient: 'Recipient',
    uploadTransactionReceipt: 'Upload transaction receipt',
    attention: 'Attention!',
    paymentConfirmed: 'Check payment status',
    cancel: 'Cancel',
    selectYourMobileDevice: 'Select your mobile device',
    paymentTypes: {
      sberpay: '',
      tpay: '',
      card: 'Make a transfer via card number',
      phone: 'Make a transfer via phone number',
      sbp: '',
      walletm10: 'Make a transfer via M10 using the phone number',
      local_banks: 'Make a transfer using the account number',
      account: 'Make a transfer using the account number',
    },
    paymentTips: {
      doNotChangeTransferAmount: 'Do not change the transfer amount',
      doNotSpecifyTransferComment: 'Do not specify a comment for the transfer',
      ensureRecipientNameAndBankMatchDetails: 'Ensure that the recipient name and bank match the provided details',
      paymentMustBeMadeInOneAmount: 'The payment must be made in one amount',
      pressCancelToSelectAnotherBank: 'To select another bank, press "Cancel" and create the payment again.',
      transferToAccountNumberAbove: 'Transfer to the account number provided above',
      ifUnableToPayPressCancel: 'If you cannot pay, press the «Cancel» button',
      enterPaymentAmountAndSubmit: 'In the application, indicate the payment amount that you indicated when replenishing and click «Submit»',
      card1: 'Transfer the strictly specified amount',
      card2: 'When transferring, check that the card number matches the one specified above',
      card3: 'After the transfer, check the payment status',
      card4: 'If you couldn\'t pay, click the "Cancel" button',
      sberpay1: 'Choose your mobile platform',
      sberpay2: 'Scan the QR',
      sberpay3: 'Send the exact amount in Sberbank application',
      sberpay4: 'Check payment status after the transfer',
      sberpay5: 'Use "Cancel" to cancel the payment',
      sberpayMobile1: 'Send the exact amount in Sberbank application',
      sberpayMobile2: 'Check payment status after the transfer',
      sberpayMobile3: 'Use "Cancel" to cancel the payment',
      sbp1: 'Send the exact amount in your bank application',
      sbp2: 'Check benificiary during the payment',
      sbp3: 'Check payment status after the transfer',
      sbp4: 'Use "Cancel" to cancel the payment',
    },
  },
  CashDeliveryForm: {
    enterCourierData: 'Enter courier data',
    saveConfirmationCode: 'Save confirmation code',
    confirmationRequiredForCourier: 'It will be required when meeting the courier',
    confirmationCodePrompt: 'Confirmation code:',
    attentionWarning: 'Attention!',
    courierContactUsageNotice: 'The provided data will be used by the courier to contact you',
    orderAcceptedMessage: 'Thank you, your order has been accepted.',
    courierConfirmationMessage: 'Please, wait for a confirmation message from the courier.',
    sendCourier: 'Send courier',
    label: {
      messenger: 'Messenger',
      phone: 'Phone number',
      login: 'Messenger login/phone number',
      address: 'Meeting address with the courier',
      immediateDelivery: 'Deliver immediately',
      deliveryDateTime: 'Delivery date and time',
    },
    error: {
      required: 'This field is required',
      incorrectNumber: 'Incorrect number',
      incorrectLogin: 'Incorrect login',
    },
  },
  StatusBlock: {
    paymentSuccessful: 'Payment successful',
    paymentDeclined: 'No payments recieved',
    returnToShop: 'Return back',
    status: {
      somethingWentWrong: 'Something went wrong.',
      waitingForFunds: 'Waiting for funds to arrive.',
      operationCancelled: 'Operation cancelled.',
      paymentTimeExpired: 'Payment time expired.',
    },
    return: {
      returnToShopAndRetryPayment: 'You can return back to site to retry the payment',
      returnToShop: 'Return back',
    },
  },
  Upload: {
    ok: 'OK',
    upload: 'Upload',
    error: 'Error',
  },
  FatalError: {
    somethingWentWrong: 'Something went wrong.',
    returnToShopAndRetryPayment: 'You can return back to site to retry the payment',
    returnToShop: 'Return back',
    returnToPaymentMethodSelection: 'Return to payment method selection',
    cancel: 'Cancel',
    code: {
      NO_TERMINALS_AVAILABLE: 'No terminals available for current payment method.',
      FORM_PAY_ALREADY_SUBMITED: 'Please return to the store to make the payment again.',
      COMMON_ERROR: 'Error during payment request.',
      DUPLICATE_ORDER_ERROR: 'Order already exists.',
      ROUTE_ERROR: 'This payment is not possible.',
    },
  },
}
