import {Button, CircularProgress, Typography} from '@mui/material'
import {redirect} from '../../utils/redirect'
import {useSelector} from 'react-redux'
import {updateStatuses} from '../../config/updateStatuses'
import {formSlice} from '../../store/form/formSlice'
import {useEffect} from 'react'
import {dispatch} from '../../store/store'
import {sbankStatuses} from '../../config/sbankStatuses'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import {useTranslation} from "react-i18next"
import {PageHeader} from "../PageHeader/PageHeader";
import {getRequisiteType} from "../../config/paymentTypes";

export function StatusBlock() {
  const {t} = useTranslation('translation', {keyPrefix: 'StatusBlock'})
  const {t : ct} = useTranslation('translation', {keyPrefix: 'common'})
  const form = useSelector(store => store.form)
  const {params, update, formData} = form
  const {finish_url} = params

  const payloadStatus = update.data.payload_status
  const paymentStatus = form.status?.data?.payment_status ?? sbankStatuses.PENDING

  let statusText = t('status.somethingWentWrong')
  // let returnText = t('return.returnToShopAndRetryPayment')

  useEffect(() => {
    dispatch(formSlice.actions.status())
    const intervalId = setInterval(() => {
      dispatch(formSlice.actions.status())
    }, 2e3)
    return () => {
      clearInterval(intervalId)
    };
  }, []);

  // clear localstorage -> invoice_id
  // save requisites => {timestamps, local}
  // показываем выбранные реквизиты
  const {beneficiary, proof_required} = formData.data
  let {type: paymentType, displayType, helpTexts, helperTextsMobile, sberpay, title} = getRequisiteType(beneficiary, params, form)

  switch (payloadStatus) {
    case updateStatuses.paid:
      statusText = t('status.waitingForFunds')
      // returnText = t('returnToShop')
      break
    case updateStatuses.cancelled:
      statusText = t('status.operationCancelled')
      // returnText = t('return.returnToShopAndRetryPayment')
      break
    case updateStatuses.timeout:
      statusText = t('status.paymentTimeExpired')
      // returnText = t('return.returnToShopAndRetryPayment')
      break
    default:
      break
  }

  return (
    <div>
      {/*<Typography variant="h5">*/}
      {/*  {ct(`title.${paymentType}`)}*/}
      {/*</Typography>*/}

      <PageHeader hideCopy={true} />

      {!(paymentStatus === sbankStatuses.COMPLETE || paymentStatus === sbankStatuses.FAILED) && (
        <Typography style={{fontSize: 24}}>
          {statusText}
        </Typography>
      )}

      {paymentStatus === sbankStatuses.PENDING && (
        <div style={{flexGrow: 1, margin: `60px 0`, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress
            size={120}
            thickness={2}
          />
        </div>
      )}

      {paymentStatus === sbankStatuses.COMPLETE && (
        <>
          <Typography style={{fontSize: 24}}>
            {t('paymentSuccessful')}
          </Typography>
          <div style={{flexGrow: 1, margin: `60px 0`, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CheckCircleIcon
              style={{fontSize: 128, color: '#4caf50'}}
            />
          </div>
        </>
      )}

      {paymentStatus === sbankStatuses.FAILED && (
        <>
          <Typography style={{fontSize: 24}}>
            {t('paymentDeclined')}
          </Typography>
          <div style={{flexGrow: 1, margin: `60px 0`, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <ErrorIcon
              style={{fontSize: 128, color: '#ef5350'}}
            />
          </div>
        </>
      )}

      {finish_url && (
        <>
          <Button
            onClick={() => redirect(finish_url)}
            sx={{mt: 3}}
            fullWidth
            variant="contained"
            size="large"
          >
            {t('returnToShop')}
          </Button>
        </>
      )}

      {/*<Button*/}
      {/*  onClick={() => ''}*/}
      {/*  sx={{mb: 2.5, mt: 4}}*/}
      {/*  fullWidth*/}
      {/*  variant="outlined"*/}
      {/*  size="large"*/}
      {/*>*/}
      {/*  {t('wtf')}*/}
      {/*</Button>*/}
    </div>
  )
}
