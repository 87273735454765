import {
  Button, CircularProgress, Typography,
} from '@mui/material'
import {useSelector} from 'react-redux'
import {getBankInfo} from '../../../../config/bankInfo'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {dispatch} from '../../../../store/store'
import {formSlice} from '../../../../store/form/formSlice'
import {getRequisiteType} from '../../../../config/paymentTypes'
import './cardForm.css'
import {useTranslation} from "react-i18next"
import {Layout} from '../Layout/Layout'
import {RequisitesCard} from '../Requisites/RequisitesCard'
import styles from '../base.module.scss'
import {Amount} from '../Amount/Amount';
import {stylesObject} from '../stylesObject';
import {QRCode} from '../../../../components/QRCode/QRCode';
import {mobileUtils} from '../../../../utils/mobileUtils';
import {LoadingButton} from '@mui/lab';
import {getDeepLinks} from '../../../../config/deepLinks';
import {deepLinksButtons} from '../../../../components/deepLinksButtons/deepLinksButtons';
import {sleep} from '../../../../utils/sleep';

export function CardForm() {
  const {t, i18n} = useTranslation('translation', {keyPrefix: 'CardForm'})
  const {t: ct} = useTranslation('translation', {keyPrefix: 'common'})
  const form = useSelector(store => store.form)
  const {formData, formTime, params} = form

  const [isMobile] = useState(mobileUtils.isMobile())

  const [isDeepLinksProcessing, setIsDeepLinksProcessing] = useState(false)
  const startProcessing = useCallback(async () => {
    setIsDeepLinksProcessing(true)
    const {beneficiary} = formData?.data
    const links = getDeepLinks(
      mobileUtils.getMobileOS(),
      form.paymentWidgetData.display_type,
      beneficiary.requisite_type,
      {
        ...beneficiary,
        amount: Number(form.formInfo.amount)
      },
    )
    localStorage.setItem(`DeepRedirectData-${params.invoice_id}`, JSON.stringify({
      invoice_id: params.invoice_id,
      isFinished: false,
      isFound: false,
      isConfirmed: false,
      isFailToPay: false,
      currentLinkIndex: 0,
      links,
    }))

    window.location = window.location.href
  }, [formData])

  /* deep redirect   */
  const SberpayRedirectRawData = localStorage.getItem(`DeepRedirectData-${params.invoice_id}`)
  const SberpayRedirectData = JSON.parse(SberpayRedirectRawData)
  const links = SberpayRedirectData?.links
  const currentLinkIndex = SberpayRedirectData?.currentLinkIndex

  useEffect(() => {
    void async function () {
      if (links && links[currentLinkIndex]) {
        let found = false
        const deepLink = links[currentLinkIndex]

        window.focus()

        function onBlur(e) {
          // found = true
        }

        window.addEventListener("blur", onBlur);

        await sleep(25)

        setTimeout(() => {
          window.location = deepLink.link
        })

        await sleep(500)

        window.removeEventListener("blur", onBlur)

        if (found) {
          localStorage.setItem(`DeepRedirectData-${params.invoice_id}`, JSON.stringify({
            ...SberpayRedirectData,
            isFinished: true,
            isFound: true,
            currentLinkIndex: currentLinkIndex,
          }))
        } else {
          localStorage.setItem(`DeepRedirectData-${params.invoice_id}`, JSON.stringify({
            ...SberpayRedirectData,
            isFinished: false,
            currentLinkIndex: currentLinkIndex + 1,
          }))
        }
        window.location = window.location.href
      } else {
        if (SberpayRedirectData && !SberpayRedirectData.isFinished) {
          localStorage.setItem(`DeepRedirectData-${params.invoice_id}`, JSON.stringify({
            ...SberpayRedirectData,
            isFinished: true,
          }))
        }
      }
    }()
  }, [links, currentLinkIndex])
  /*   */

  const languageTag = i18n.language === 'ru' ? 'ru' : 'en'
  const {banks} = form

  const sourceBanks = banks?.data?.payment_widget_data?.source_banks ?? {}
  const bankData = sourceBanks[form.selectedSourceBank?.key]

  const widgetData = banks?.data?.payment_widget_data ?? {}

  let instructions = widgetData.instructions
  let instructionsDesktop = widgetData.instructions_desktop

  if (bankData && bankData.instructions) {
    instructions = bankData.instructions
  }
  if(instructionsDesktop && !isMobile) {
    instructions = instructionsDesktop
  }

  const timeLimit = formData?.data?.ttl * 60 - (((Date.now() - formTime) / 1e3) | 0)
  const [time, setTime] = useState(timeLimit)
  const timeMin = String(Math.floor(Math.round(time) / 60)).padStart(2, '0')
  const timeSec = String(Math.round(time) % 60).padStart(2, '0')

  useEffect(() => {
    if (!timeLimit) {
      return
    }
    const started = Date.now()
    setTime(timeLimit - (Date.now() - started) / 1e3)
    const interval = setInterval(() => {
      setTime(timeLimit - (Date.now() - started) / 1e3)
    }, 1e3)
    return () => clearInterval(interval)
  }, [timeLimit])

  useEffect(() => {
    if (time <= 0) {
      dispatch(formSlice.actions.formTimeout())
    }
  }, [time, isMobile])

  const {beneficiary} = formData?.data ?? {}
  const bankInfo = getBankInfo(beneficiary?.bank_name)

  const {
    type: paymentType,
    displayType,
    displayTypeTitleModified,
    helpTexts,
    helperTextsMobile,
    deepLinkType,
  } = getRequisiteType(beneficiary, params, form)

  const amountRaw = form.formInfo.amount

  // form.selectedSourceBank?.key -> all
  // beneficiary.requisite_type -> default
  const instruction = displayType

  function makeHowToList(instruction) {
    if (instructions) {
      const texts = []
      let index = 0
      while (++index) {
        if (instructions[`${index}`]) {
          texts.push(instructions[`${index}`])
        }
        if (!instructions[`${index}`] && index) {
          break
        }
      }
      return texts
    }
    const texts = []
    let index = 0
    while (++index) {
      const key = `CardForm.HowToJPAY.${instruction}.${index.toString()}`
      const text = t(`HowToJPAY.${instruction}.${index.toString()}`)
      if (key === text) {
        break
      }
      texts.push(text)
    }
    return texts
  }

  const instructionEl = useMemo(() => {
    return (
      <div style={isMobile ? {margin: '16px 0'} : {margin: '0 16px'}}>
        <div className={isMobile ? styles.textSubtitle : styles.textTitle} style={{marginBottom: 16}}>
          {t(`HowToJPAY.title`)}
        </div>
        <div className={'list'}>
          {makeHowToList(instruction).map((text, index) => (
            <div
              className={`${isMobile ? styles.textSmall : styles.textMedium} ${styles.listItem}`}
              key={`key-${index}`}
              data-number={`${index + 1}.`}
            >
              {text}
            </div>
          ))}
        </div>
      </div>
    )
  }, [instruction, isMobile])

  //formData.data
  if(!formData.data) {
    return (
      <Layout
        title={`JPAY`}
      >
        <div style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {isMobile ? (
            <CircularProgress
              size={100}
              thickness={4}
              style={{color: '#55D5FF'}}
            />
          ) : (
            <CircularProgress
              size={100}
              thickness={4}
              style={{color: '#55D5FF'}}
            />
          )}
          <Typography color={'#EAF8FF'} sx={{marginTop: '36px'}}>
            {t('Loading')}
          </Typography>
        </div>
      </Layout>
    )
  }

  const requisiteValue = displayType === 'sbp' ? beneficiary.phone
    : (displayType === 'account' ? beneficiary.account_number : beneficiary.pan)

  const showQrCode = !isMobile && deepLinkType
  const DeepLinkButton = deepLinksButtons[deepLinkType]

  return (
    <Layout
      title={ct(`h1.${displayTypeTitleModified ?? displayType}`)}
      under={instructionEl}
      qrCode={showQrCode}
    >
      {isMobile ? (
        <>
          <div className={`${styles.textSubtitle} ${styles.center}`} style={{marginBottom: '16px'}}>
            {ct(`title.${displayTypeTitleModified ?? displayType}`)}
          </div>
          <div className={`${styles.textSmall} ${styles.center}`}>
            {ct(`order`, {order: form.params.invoice_id})}
          </div>

          <div style={{margin: '16px auto'}}>
            <Amount
              amount={amountRaw}
              canCopy={true}
            />
          </div>

          {deepLinkType && (
            <LoadingButton
              sx={{mb: 2.5, mt: 1.5}}
              className={`${deepLinkType}Btn`}
              loading={isDeepLinksProcessing}
              onClick={startProcessing}
            >
              <DeepLinkButton/>
            </LoadingButton>
          )}

          <RequisitesCard
            type={displayType}
            bankName={displayType === 'sbp' ?
              bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)
              :
              bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)
            }
            value={requisiteValue}
            cardOwner={beneficiary.name}
          />

          <div style={{margin: '24px auto', display: 'flex'}}>
            <span className={styles.textSmall} style={{marginRight: '6px'}}>
              {t('remainingTime')}
            </span>
            <span className={styles.textSubtitle}>
              {timeMin}:{timeSec}
            </span>
          </div>

          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{
              ...stylesObject.btnPrimary,
              marginBottom: '12px',
            }}
            onClick={() => dispatch(formSlice.actions.update({status: 'paid'}))}
          >
            {t('paymentConfirmed')}
          </Button>
          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{
              ...stylesObject.btnSecondary,
            }}
            onClick={() => dispatch(formSlice.actions.update({status: 'cancelled'}))}
          >
            {t('cancel')}
          </Button>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            gap: '32px',
          }}
        >
          <RequisitesCard
            type={displayType}
            bankName={displayType === 'sbp' ?
              bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)
              :
              bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)
            }
            value={requisiteValue}
            cardOwner={beneficiary.name}
          />

          <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
            <div className={styles.textTitle} style={{marginBottom: '12px'}}>
              {ct(`title.${displayTypeTitleModified ?? displayType}`)}
            </div>
            <div className={styles.textMedium} style={{marginBottom: '12px'}}>
              {ct(`order`, {order: form.params.invoice_id})}
            </div>

            <Amount
              amount={amountRaw}
              canCopy={true}
            />

            <div className={styles.textMedium} style={{marginTop: '12px'}}>
              {t('remainingTime')}{' '}{timeMin}:{timeSec}
            </div>

            <div style={{flexGrow: 1}}/>

            <div style={{display: 'flex', gap: '12px'}}>
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ...stylesObject.btnPrimary,
                }}
                onClick={() => dispatch(formSlice.actions.update({status: 'paid'}))}
              >
                {t('paymentConfirmed')}
              </Button>
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ...stylesObject.btnSecondary,
                }}
                onClick={() => dispatch(formSlice.actions.update({status: 'cancelled'}))}
              >
                {t('cancel')}
              </Button>
            </div>
          </div>

          {showQrCode && (
            <div className={styles.qrCodeWrapper}>
              <QRCode
                data={window.location.href}
                icon={null}
                width={220}
                height={220}
              />
            </div>
          )}
        </div>
      )}
    </Layout>
  )
}
