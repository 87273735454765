import {all, put, takeLatest, select} from 'redux-saga/effects'
import {formSlice} from './formSlice'
import {apiURL, waitTimeForStatus} from '../../config/constants'
import {fixRequisiteType} from '../../config/paymentTypes'
import {sbankStatuses} from '../../config/sbankStatuses'
import {openReplay} from '../../externals/openreplay'
import {sleep} from '../../utils/sleep';

function* init() {
  yield put(formSlice.actions.setLoading({type: 'banks', loading: true}))
  const {params} = yield select(state => state.form)

  try {
    const response = yield fetch(`${apiURL}/requisites/init`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        // TODO switch to endpoint_id completly
        product_id: params.product_id,
        endpoint_id: params.endpoint_id,
      })
    })
    const json = yield response.json()
    // const json = {
    //   "status": "success",
    //   "result": {
    //     "tinkoff": {
    //       "name_en": "T Bank",
    //       "name_ru": "Т Банк"
    //     },
    //     "gazprom": {
    //       "name_en": "Gazprombank",
    //       "name_ru": "Газпромбанк"
    //     },
    //     "sber": {
    //       "name_en": "sber",
    //       "name_ru": "sber"
    //     }
    //   },
    //   "payment_widget_data": {
    //     "display_type": "tpay",
    //     "instructions": {
    //       "1": "Отсканируйте QR-код",
    //       "2": "Если Банк просит указать назначение платежа - укажите 'дар' или 'спасибо'",
    //       "3": "Если не смогли оплатить, нажмите кнопку 'Отменить'"
    //     },
    //     "instructions_desktop": {
    //       "1": "Отсканируйте QR-код",
    //       "2": "Если Банк просит указать назначение платежа - укажите 'дар' или 'спасибо'",
    //       "3": "Если не смогли оплатить, нажмите кнопку 'Отменить'",
    //       "4": "test desktop"
    //     }
    //   }
    // }

    if (json.status === 'success') {
      Object.keys(json.result).forEach(bankKey => {
        json.result[bankKey].key = bankKey
      })

      if (json.payment_widget_data) {
        json.result.payment_widget_data = json.payment_widget_data
        yield put(formSlice.actions.setPaymentWidgetData(json.payment_widget_data))
      }

      if (json.payment_widget_data.source_banks) {
        const keys = Object.keys(json.payment_widget_data.source_banks)
        if (keys.length === 1) {
          const bank = json.payment_widget_data.source_banks[keys[0]]
          yield put(formSlice.actions.selectSourceBank({bank: {bank, key: bank.key}}))
        }
      }

      yield put(formSlice.actions.setLoading({type: 'banks', loading: false, data: json.result}))

      if (Object.keys(json.result).filter(key => key !== 'payment_widget_data').length === 1) {
        const key = Object.keys(json.result).shift()
        yield put(formSlice.actions.selectBank({bank: json.result[key]}))
      }

      if (Object.keys(json.result).filter(key => key !== 'payment_widget_data').length === 0) {
        yield put(formSlice.actions.selectBank({
          bank: {}
        }))
      }

      return
    }

    yield openReplay.trackerIsReady

    yield put(formSlice.actions.setFatalError({message: json.message}))
  } catch (e) {
    console.log('e', e)
    yield put(formSlice.actions.setFatalError({}))
  }
  yield put(formSlice.actions.setLoading({type: 'banks', loading: false}))
}

function* formSale() {
  yield put(formSlice.actions.setLoading({type: 'formData', loading: true}))
  const form = yield select(store => store.form)
  const {params} = form

  const device = form?.selectedDevice
  const reqType = device && device === 'Android' ? 'account' : 'sbp'

  try {
    const response = yield fetch(`${apiURL}/p2p-selector/form/sale`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        issuer_name: form.selectedBank?.key,
        source_bank: form.selectedSourceBank?.key,
        invoice_id: params.invoice_id,
        customer: params.customer,
        customer_id: params.customer_id,
        ...(device && reqType && {
          requisite_type: reqType
        }),
        receiver_messenger: form.cashDelivery.messenger,
        receiver_verification_code: form.cashDelivery.verification_code ? form.cashDelivery.verification_code.toString() : null,
        receiver_receive_time: form.cashDelivery.receive_time ? form.cashDelivery.receive_time.toString() : null,
        receiver_receive_asap: form.cashDelivery.receive_asap.toString(),
        receiver_address: form.cashDelivery.address,
        receiver_username: form.cashDelivery.username ? form.cashDelivery.username.replaceAll(' ', '') : undefined,
      })
    })

    const json = yield response.json()
    // const json = {
    //   "status": "success",
    //   "invoice_id": "20287",
    //   "beneficiary": {
    //     "pan": "4111111111111111",
    //     "name": "name",
    //     "name_ru":
    //     "name_en":
    //     "bank_name": "Сбербанк",
    //     "phone": "+79999999999",
    //     "account_number": null,
    //     "requisite_type": "card", //sbp // account // any
    //     // country_code: 'country',
    //     // country_phone_code: 'country_phone_code',
    //     // country_name: 'country_name',
    //   },
    //   // amount: '123.99',
    //   // amount_original: str | None
    //   amount: "4040.0"
    //   amount_original:"4000.0"
    //   // is_amount_changed: true,
    //   "currency": "RUB",
    //   "ttl": "15",
    //   "finish_url": null
    //   proof_required: false
    // }
    // const json = {
    //   "status": "success",
    //   "invoice_id": "1880043",
    //   amount: '123',
    //   amount_original: '123',
    //   is_amount_changed: true,
    //   "beneficiary": {
    //     "pan": "4000000000000000",
    //     "name": "Test Test",
    //     "bank_name": "ATB bank",
    //     "phone": null,
    //     "account_number": null,
    //     "requisite_type": null
    //   },
    //   "ttl": "15",
    //   "finish_url": "https://google.com?amount=10.0000&invoice_id=1880043&product_id=139&merchant_id=22&order=787df9e768f827e8&currency=AZN&signature=SufY8kTKF-1O4iZqu91pfNFiAzScvDacnvfu4AIr3eQ"
    // }

    if (json.status === 'error') {
      yield put(formSlice.actions.setFatalError({message: json.message, code: json.code}))
    }

    if (json.status === 'success') {

      const {pan, phone, account_number} = json.beneficiary
      if(!pan && !phone && !account_number) {
        yield sleep(5e3)
        yield put(formSlice.actions.fetchData())
        return
      }

      json.beneficiary.requisite_type = fixRequisiteType(json.beneficiary)
      json.beneficiary.bank_name = json.beneficiary.bank_name ?? ''

      yield put(formSlice.actions.setFormInfo({
        amount: json.amount,
        amountFormatted: new Intl.NumberFormat('ru-Ru').format(json.amount).replace(',', '.'),
        currency: json.currency,
        isAmountChanged: json.is_amount_changed,
      }))

      yield put(formSlice.actions.setLoading({type: 'formData', loading: false, data: json}))
      yield put(formSlice.actions.setFormTime())
      yield put(formSlice.actions.saveInCache())
      return
    }
  } catch (e) {
    console.log('e', e)
    yield put(formSlice.actions.setFatalError({}))
  }
  yield put(formSlice.actions.setLoading({type: 'formData', loading: false}))
}

function* update({payload}) {
  yield put(formSlice.actions.setLoading({type: 'update', loading: true}))
  const form = yield select(store => store.form)
  const {params} = form

  try {
    const response = yield fetch(`${apiURL}/p2p-selector/update`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        invoice_id: params.invoice_id,
        status: payload.status,
      })
    })
    const json = yield response.json()

    json.payload_status = payload.status

    if (json.status === 'success') {
      yield put(formSlice.actions.setLoading({type: 'update', loading: false, data: json}))
    } else {
      yield put(formSlice.actions.setFatalError({message: json.message}))
    }
  } catch (e) {
    yield put(formSlice.actions.setFatalError({}))
  }
  yield put(formSlice.actions.setFormUpdateTime())
  yield put(formSlice.actions.setLoading({type: 'update', loading: false}))
  yield put(formSlice.actions.saveInCache())
}

function* status() {
  const form = yield select(store => store.form)
  const statusURL = form.formData.data.status_url
  yield put(formSlice.actions.setLoading({type: 'status', loading: true}))

  if (Date.now() - form.formUpdateTime > waitTimeForStatus * 1e3) {
    yield put(formSlice.actions.setLoading({
      type: 'status',
      loading: false,
      data: {payment_status: sbankStatuses.FAILED}
    }))
    yield put(formSlice.actions.saveInCache())
    return
  }

  try {
    const response = yield fetch(statusURL, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    const json = yield response.json()
    if (json.status === 'success') {
      yield put(formSlice.actions.setLoading({type: 'status', loading: false, data: json}))
    } else {
      yield put(formSlice.actions.setFatalError({message: json.message}))
    }
  } catch (e) {
    console.log('e', e)
    yield put(formSlice.actions.setFatalError({}))
  }
  yield put(formSlice.actions.setLoading({type: 'status', loading: false}))
  yield put(formSlice.actions.saveInCache())
}

export default function* formSagas() {
  yield all([
    yield takeLatest(formSlice.actions.fetchBanks.type, init),
    yield takeLatest(formSlice.actions.fetchData.type, formSale),
    yield takeLatest(formSlice.actions.update.type, update),
    yield takeLatest(formSlice.actions.status.type, status),
  ])
}
