// import {requisiteTypes} from './requisiteTypes';
// requisiteTypes
// sbp/card/account

// account_number phone pan

// - btripsexpenses
// - ios-app-smartonline
// - budgetonline-ios
// - sberbankonline
// - sbolonline

const requisiteTypeMapping = {
  card: 'pan',
  account: 'account_number',
  sbp: 'phone',
}

export const deepLinksTypes = ['sberpay', 'tpay']

const deepLinks = {
  sberpay: [
    {
      link: `intent://ru.sberbankmobile/payments/p2p?type={type}&requisiteNumber={requisiteNumber}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account_number',
        card: 'card_number',
        sbp: 'card_number',
      },
      params: {
        type: 'requisiteType',
        requisiteNumber: 'requisite',
        amount: 'amount',
      },
      platforms: ['android'], // ios, android
      requisiteTypes: ['sbp', 'card', 'account'], // sbp, card, account
    },
    //
    {
      link: `btripsexpenses://sbolonline/p2ptransfer?type={type}&to={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp', 'card', 'account'],
    },
    {
      link: `ios-app-smartonline://sbolonline/p2ptransfer?type={type}&to={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp', 'card', 'account'],
    },
    {
      link: `budgetonline-ios://sbolonline/p2ptransfer?type={type}&to={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp', 'card', 'account'],
    },
    {
      link: `sbolonline://p2ptransfer?type={type}&to={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp', 'card', 'account'],
    },
    {
      link: `sberbankonline://p2ptransfer?type={type}&to={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp', 'card', 'account'],
    },
  ],
  tpay: [
    {
      link: `tinkoffbank://Main/Pay/C2C?targetCardNumber={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios', 'android'],
      requisiteTypes: ['card', 'account'],
    },
    {
      link: `bank100000000004://Main/PayByMobileNumber?amount={amount}&bankMemberId={bank_member_id}&numberPhone=%2B{to}&workflowType=RTLNTransfer`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        bank_member_id: 'bank_member_id',
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp'],
    },
    {
      link: `tinkoffbank://Main/PayByMobileNumber?amount={amount}&bankMemberId={bank_member_id}&numberPhone=%2B{phone}&workflowType=RTLNTransfer`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        bank_member_id: 'bank_member_id',
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['android'],
      requisiteTypes: ['sbp'],
    },
  ]
}

export function getDeepLinks(platform, type, requisiteType, params) {

  const filteredLinks = deepLinks[type].filter((link) => {
    if (link.platforms.includes(platform) && link.requisiteTypes.includes(requisiteType)) {
      return true
    }
  })

  filteredLinks.forEach(link => {
    const deepLinkParamsValues = {
      ...params,
      requisite: params[requisiteTypeMapping[requisiteType]],
      requisiteType: link.requisiteTypeMapping[requisiteType]
    }

    Object.entries(link.params).forEach(([replace, param]) => {
      link.link = link.link.replace(`{${replace}}`, deepLinkParamsValues[param])
    })
  })

  return filteredLinks
}
