import {
  Box,
  Button,
  Card,
  CircularProgress,
  Tooltip,
  Typography, useMediaQuery, useTheme
} from '@mui/material'
import {useSelector} from 'react-redux'
import {getBankInfo} from '../../../../config/bankInfo'
import React, {useEffect, useState} from 'react'
import {dispatch} from '../../../../store/store'
import {formSlice} from '../../../../store/form/formSlice'
import {copyToClipboard} from '../../../../utils/copyToClipboard'
import {IconButton} from '@mui/material'
import {useTranslation} from "react-i18next"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import copyWhite from '../../assets/copyWhite.svg'
import copyBlack from '../../assets/copyBlack.svg'
import greenArrow from '../../assets/greenArrow.svg'
import info from '../../assets/info.svg'
import grayInfo from '../../assets/grayInfo.svg'
import {Header} from '../Header'
import styles from './CardForm.module.scss'
import {mobileUtils} from '../../../../utils/mobileUtils';

const instruction = [
  'Откройте приложение Банка',
  'Выберите переводы по номеру телефона',
  'Вставьте номер телефона получателя и выберите Банк',
  'Вставьте сумму и подтвердите перевод',
]

export function CardForm() {
  const {t, i18n} = useTranslation('translation', {keyPrefix: 'CardForm'})
  const {t: ct} = useTranslation('translation', {keyPrefix: 'common'})
  const form = useSelector(store => store.form)
  const {formData, formTime, params, selectedDevice} = form
  const [isPriceChangedModal, setIsPriceChangedModal] = useState(true)

  const [mobile] = useState(mobileUtils.isMobile())

  const languageTag = i18n.language === 'ru' ? 'ru' : 'en'

  useEffect(() => {
    if (!formData.data) {
      dispatch(formSlice.actions.fetchData())
    }
  }, [])

  const [toolTipsState, setToolTipsState] = React.useState({
    account_number: {
      isOpen: false,
      timeout: null,
    },
    pan: {
      isOpen: false,
      timeout: null,
    },
    name: {
      isOpen: false,
      timeout: null,
    },
    phone: {
      isOpen: false,
      timeout: null,
    },
    bank: {
      isOpen: false,
      timeout: null,
    },
    amount: {
      isOpen: false,
      timeout: null,
    },
    info: {
      isOpen: false,
      timeout: null,
      noTimeOut: true,
    },
  })
  const handleTooltipClose = (key) => {
    clearTimeout(toolTipsState[key].timeout)
    setToolTipsState({
      ...toolTipsState,
      [key]: {
        ...toolTipsState[key],
        isOpen: false,
        timeout: null,
      }
    })
  }
  const handleTooltipOpen = (key) => {
    clearTimeout(toolTipsState[key].timeout)
    setToolTipsState({
      ...toolTipsState,
      [key]: {
        ...toolTipsState[key],
        isOpen: true,
        timeout: setTimeout(() => {
          if (!toolTipsState[key].noTimeOut) {
            handleTooltipClose(key)
          }
        }, 500)
      }
    })
  }

  const timeLimit = formData?.data?.ttl * 60 - (((Date.now() - formTime) / 1e3) | 0)
  const [time, setTime] = useState(timeLimit)

  const timeMin = String(Math.floor(Math.round(time) / 60)).padStart(2, '0')
  const timeSec = String(Math.round(time) % 60).padStart(2, '0')

  useEffect(() => {
    if (!timeLimit) {
      return
    }
    const started = Date.now()
    setTime(timeLimit - (Date.now() - started) / 1e3)
    const interval = setInterval(() => {
      setTime(timeLimit - (Date.now() - started) / 1e3)
    }, 1e3)
    return () => clearInterval(interval)
  }, [timeLimit])

  useEffect(() => {
    if (time <= 0) {
      dispatch(formSlice.actions.formTimeout())
    }
  }, [time])

  if (formData.loading || !formData.data || (Number.isInteger(time) && time < 0)) {
    return (
      <div style={{flexGrow: 1, marginTop: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{
          flexGrow: 1,
          marginTop: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CircularProgress
            size={46}
            thickness={1}
            style={{color: '#FFFFFF'}}
          />
        </div>
      </div>
    )
  }

  const {beneficiary} = formData.data
  const bankInfo = getBankInfo(beneficiary.bank_name)

  const amount = form.formInfo.amountFormatted
  const amountRaw = form.formInfo.amount
  const showPriceChanged = form.formInfo.isAmountChanged && isPriceChangedModal

  return (
    <>
      {!mobile && showPriceChanged && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 3,
            background: '#000',
            opacity: .5,
            minHeight: '100vh',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            padding: 32,
            boxSizing: "border-box"
          }}
        />
      )}
      <div style={{color: '#FFF'}} className={`${styles.card} ${mobile ? styles.mobile : null}`}>
        <Header/>
        {mobile && showPriceChanged && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 3,
              background: '#000',
              opacity: .5,
              minHeight: '100vh',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              padding: 32,
              boxSizing: "border-box"
            }}
          />
        )}

        <Typography
          style={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '28px',
            textAlign: 'center',
            padding: `0 ${mobile ? '0' : '60px'}`,
            margin: '16px auto'
        }}>
          Скопируйте реквизиты для перевода по номеру телефона через СБП
        </Typography>

        {mobile ? (
          <div style={{position: 'relative'}}>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 600,
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 24
              }}
            >
              <div>
                {toolTipsState.info.isOpen && (
                  <div
                    className={`${styles.blob} ${styles.right}`}
                    style={{
                      position: "absolute",
                      borderRadius: 6,
                      height: 144,
                      right: '0',
                      top: -154,
                      zIndex: 10,
                      background: '#fff',
                      padding: '12px 16px',
                      boxSizing: "border-box",
                      color: "#000",
                      display: "flex",
                      flexDirection: "column",
                      left: 'calc(50% - 160px)',
                      maxWidth: '320px',
                    }}
                  >
                    <div style={{fontSize: '16px', fontWeight: 700, lineHeight: '18px'}}>
                      Инструкция:
                    </div>
                    <div className={'list'} style={{marginTop: 8}}>
                      {instruction.map((text, index) => (
                        <span
                          key={`key-${index}`}
                          data-number={`${index + 1}.`}
                          style={{fontSize: 12, fontWeight: 400,}}
                        >
                          {text}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                Соблюдайте эту инструкцию >
                <ClickAwayListener onClickAway={() => handleTooltipClose('info')}>
                  <IconButton
                    style={{marginLeft: 6}}
                    onClick={() => {
                      handleTooltipOpen('info')
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        height: 12,
                        width: 12,
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          background: `url("${info}") no-repeat center center`,
                          left: -6,
                          top: -6,
                          height: 24,
                          width: 24,
                          backgroundSize: 'cover',
                        }}
                      />
                    </div>
                  </IconButton>
                </ClickAwayListener>
              </div>
            </Typography>
          </div>
        ) : (
          <div style={{position: 'relative'}}>
            <Typography style={{fontSize: 24, fontWeight: 500, width: 320, margin: '16px auto 36px auto'}}>
              Соблюдайте инструкцию
              <span
                style={{
                  marginLeft: 6,
                  display: 'inline-block',
                  position: 'absolute',
                  background: `url("${greenArrow}") no-repeat center center`,
                  height: 36,
                  width: 36,
                  backgroundSize: 'cover',
                }}
              />
            </Typography>
            <div className={styles.help}>
              <div style={{fontSize: 28, fontWeight: 600}}>
                Инструкция:
              </div>
              <div className={'list'} style={{marginTop: 8}}>
                {instruction.map((text, index) => (
                  <span
                    key={`key-${index}`}
                    data-number={`${index + 1}.`}
                    style={{fontSize: 20, lineHeight: '24px', marginTop: '8px'}}
                  >
                    {text}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}

        <Card style={{backgroundColor: "#18222C", color: "#FFF", padding: '24px', borderRadius: '12px'}}>
          <div style={{position: "relative"}}>
            {mobile && (
              <div className={styles.brownCard}/>
            )}
            <Typography
              style={{fontSize: 18, marginBottom: 8, fontWeight: 500, color: 'rgba(255,255,255,.8)', position: 'relative', zIndex: 2}}>
              Номер получателя:
            </Typography>
            <div
              style={{display: 'flex', justifyContent: 'space-between', position: 'relative', zIndex: 2}}>
              <Typography style={{fontSize: 26, fontWeight: 600}}>
                {beneficiary.phone}
              </Typography>
              <ClickAwayListener onClickAway={() => handleTooltipClose('phone')}>
                <Tooltip
                  placement="top-end"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => handleTooltipClose('phone')}
                  open={toolTipsState.phone.isOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={ct('copied')}
                >
                  <IconButton
                    style={{marginTop: 0, background: '#828B8B', borderRadius: 6}}
                    onClick={() => {
                      copyToClipboard(beneficiary.phone)
                      handleTooltipOpen('phone')
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        height: 24,
                        width: 24,
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          background: `url("${copyWhite}") no-repeat center center`,
                          left: -6,
                          top: -6,
                          height: 36,
                          width: 36,
                          backgroundSize: 'cover',
                        }}
                      />
                    </div>
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </div>
          </div>

          <Typography
            style={{
              fontSize: 18,
              marginBottom: 8,
              marginTop: 16,
              fontWeight: 500,
              color: 'rgba(255,255,255,.8)'
            }}>
            Банк получателя:
          </Typography>
          <div style={{display: 'flex', justifyContent: 'space-between', position: 'relative', zIndex: 2}}>
            <Typography style={{fontSize: 26, fontWeight: 600}}>
              {bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)}
            </Typography>
            <ClickAwayListener onClickAway={() => handleTooltipClose('bank')}>
              <Tooltip
                placement="top-end"
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => handleTooltipClose('bank')}
                open={toolTipsState.bank.isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={ct('copied')}
              >
                <IconButton
                  style={{marginTop: 0, background: '#828B8B', borderRadius: 6}}
                  onClick={() => {
                    copyToClipboard(bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name))
                    handleTooltipOpen('bank')
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      height: 24,
                      width: 24,
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        background: `url("${copyWhite}") no-repeat center center`,
                        left: -6,
                        top: -6,
                        height: 36,
                        width: 36,
                        backgroundSize: 'cover',
                      }}
                    />
                  </div>
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          </div>

          <div className={styles.greenCard} style={{marginTop: 26}}>
            {showPriceChanged && (
              <div
                className={`${styles.blob} ${mobile ? styles.mobile : styles.desktop}`}
                style={{
                  position: "absolute",
                  borderRadius: 6,
                  top: '-168px',
                  height: 146,
                  left: '-12px',
                  right: '-12px',
                  zIndex: 10,
                  background: '#fff',
                  padding: '12px 16px',
                  boxSizing: "border-box",
                  color: "#000",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography mb={.5}
                            style={{fontSize: mobile ? 16 : 18, fontWeight: 700, lineHeight: `18px`}}>
                  Скопируйте сумму для депозита
                </Typography>

                <Typography mb={.5}
                            style={{fontSize: mobile ? 12 : 14, fontWeight: 400, lineHeight: `14px`}}>
                  Мы изменили сумму вашего перевода, чтобы убедиться, что это ваш платеж и зачислить
                  его автоматически.
                </Typography>

                <Typography
                  mb={.5}
                  style={{
                    fontSize: mobile ? 12 : 14,
                    fontWeight: 400,
                    lineHeight: `14px`,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      flexShrink: 0,
                      marginRight: 6,
                      display: 'inline-block',
                      background: `url("${grayInfo}") no-repeat center center`,
                      height: 18,
                      width: 18,
                      backgroundSize: 'cover',
                    }}
                  />Вся сумма поступит на ваш баланс
                </Typography>

                <div style={{flexGrow: 1}}/>

                <Button
                  size="small"
                  variant="contained"
                  sx={{mt: .5}}
                  onClick={() => setIsPriceChangedModal(false)}
                >
                  Хорошо
                </Button>
              </div>
            )}

            <div className={`${styles.greenCardAnimation} ${showPriceChanged ? styles.active : ''}`}/>
            <div className={`${styles.greenCardContent} ${showPriceChanged ? styles.active : ''}`}>
              <Typography
                style={{
                  fontSize: 18,
                  marginBottom: 8,
                  marginTop: 16,
                  fontWeight: 500,
                  color: 'rgba(255,255,255,.8)'
                }}
              >
                Сумма перевода:
              </Typography>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography style={{fontSize: 26, fontWeight: 600}}>
                  {amount} RUB
                </Typography>
                <ClickAwayListener onClickAway={() => handleTooltipClose('amount')}>
                  <Tooltip
                    placement="top-end"
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => handleTooltipClose('amount')}
                    open={toolTipsState.amount.isOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={ct('copied')}
                  >
                    <IconButton
                      style={{marginTop: 0, background: '#FFF', borderRadius: 6}}
                      onClick={() => {
                        copyToClipboard(Number(amountRaw))
                        handleTooltipOpen('amount')
                      }}
                    >
                      <div
                        style={{
                          position: 'relative',
                          height: 24,
                          width: 24,
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            background: `url("${copyBlack}") no-repeat center center`,
                            left: -6,
                            top: -6,
                            height: 36,
                            width: 36,
                            backgroundSize: 'cover',
                          }}
                        />
                      </div>
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </div>
            </div>
          </div>

        </Card>

        {/*<Typography mt={1} style={{fontSize: 14}}>*/}
        {/*  {t('sbpFio')}*/}
        {/*</Typography>*/}
        {/*<div style={{display: 'flex', justifyContent: 'space-between'}}>*/}
        {/*  <Typography style={{fontSize: 20, fontWeight: 600}}>*/}
        {/*    {beneficiary.name}*/}
        {/*  </Typography>*/}
        {/*  <ClickAwayListener onClickAway={() => handleTooltipClose('name')}>*/}
        {/*    <Tooltip*/}
        {/*      PopperProps={{*/}
        {/*        disablePortal: true,*/}
        {/*      }}*/}
        {/*      onClose={() => handleTooltipClose('name')}*/}
        {/*      open={toolTipsState.name.isOpen}*/}
        {/*      disableFocusListener*/}
        {/*      disableHoverListener*/}
        {/*      disableTouchListener*/}
        {/*      title={ct('copied')}*/}
        {/*    >*/}
        {/*      <IconButton*/}
        {/*        style={{marginTop: -6}}*/}
        {/*        onClick={() => {*/}
        {/*          copyToClipboard(beneficiary.name)*/}
        {/*          handleTooltipOpen('name')*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <ContentCopyIcon color="primary"/>*/}
        {/*      </IconButton>*/}
        {/*    </Tooltip>*/}
        {/*  </ClickAwayListener>*/}
        {/*</div>*/}

        <Box style={{color: 'rgba(255,255,255,.8)', fontSize: 16, fontWeight: 400, marginTop: 32}}>
          Время на перевод:
          {' '}
          <Typography component="span" style={{fontSize: 16, fontWeight: 400, color: '#FFF'}}>
            {timeMin}:{timeSec}
          </Typography>
        </Box>

        <Button
          onClick={() => dispatch(formSlice.actions.update({status: 'paid'}))}
          sx={{mb: 2.5, mt: 1.5}}
          fullWidth
          variant="contained"
          size="large"
          style={{color: '#fff'}}
        >
          {t('paymentConfirmed')}
        </Button>

        <Button
          variant="outlined"
          style={{color: '#FFF'}}
          onClick={() => dispatch(formSlice.actions.update({status: 'cancelled'}))}
          fullWidth size="large"
        >
          {t('cancel')}
        </Button>
      </div>
    </>
  )
}
