import {SourceBankList} from './components/SourceBankList/SourceBankList'
import {useSelector} from 'react-redux'
import {CardForm} from './components/CardForm/CardForm'
import React, {useEffect, useRef, useState} from 'react'
import Montserrat from './asssets/Montserrat.ttf'
import {CSSTransition} from 'react-transition-group'
import styles from './components/base.module.scss'
import {Preloading} from './components/Preloading/Preloading'
import './index.css'
import {dispatch} from '../../store/store'
import {formSlice} from '../../store/form/formSlice'
import {AmountChanged} from './components/AmountChanged/AmountChanged'
import {Status} from './components/Status/Status'
import {FatalError} from './components/FatalError/FatalError'
import {sleep} from '../../utils/sleep'

export function FormPage() {
  const form = useSelector(store => store.form)
  const {
    banks,
    selectedSourceBank,
    update,
    fatalError,
    params,
    formData,
    skipBankSelect,
    formInfo,
  } = form
  const [preloading, setPreloading] = useState(true)
  const [prevPage, setPrevPage] = useState('Preloading')
  const [activePage, setActivePage] = useState('Preloading')
  const [isPriceChangedAccepted, setIsPriceChangedAccepted] = useState(false)
  const selectedSourceBankEnabled = params?.is_source_bank_guess_enabled && params?.is_source_bank_guess_enabled.toLowerCase() === "true"

  const formRef = useRef(form)
  const banksRef = useRef(banks)
  const formDataRef = useRef(formData)
  const fatalErrorRef = useRef(fatalError)

  formRef.value = form
  banksRef.value = banks
  formDataRef.value = formData
  fatalErrorRef.value = fatalError

  const changePage = (page) => {
    if(page !== activePage) {
      setPrevPage(activePage)
    }
    setActivePage(page)
  }

  const nodeRefSourceBankList = useRef(null)
  const nodeRefAmountChanged = useRef(null)
  const nodeRefCardForm = useRef(null)
  const nodeRefFatalError = useRef(null)
  const nodeRefStatus = useRef(null)

  const routing = () => {
    if ((!selectedSourceBankEnabled || selectedSourceBank) && activePage === 'SourceBankList') {
      return changePage('CardForm')
    } else {
      // skip source
    }
    if (fatalError) {
      return changePage('FatalError')
    }
    if (update.data) {
      return changePage('Status')
    }
    if (formData.data) {
      const SberpayRedirectRawData = localStorage.getItem(`DeepRedirectData-${params.invoice_id}`)
      const SberpayRedirectData = JSON.parse(SberpayRedirectRawData)

      if (formData?.data?.is_amount_changed && !isPriceChangedAccepted) {
        if(!SberpayRedirectData) {
          return changePage('AmountChanged')
        }
      }
      return changePage('CardForm')
    }
  }

  useEffect(() => {
    let pages = ['SourceBankList', 'AmountChanged', 'CardForm', 'FatalError', 'Status']

    function initPage(page) {
      if (!page) {
        routing()
        if (selectedSourceBankEnabled) {
          //
        } else {
          changePage('CardForm')
        }
        setTimeout(async () => {
          while (true) {
            await sleep(1)
            // if(isDeepLink && banksRef.value.data) {
            //   break
            // }
            // if (selectedSourceBankEnabled || !formRef.value.selectedSourceBank) {
            if (selectedSourceBankEnabled) {
              if (banksRef.value.data) {
                break
              }
            } else {
              if (banksRef.value.data && formDataRef.value.data) {
                changePage('CardForm')
                break
              }
              if (fatalErrorRef.value) {
                changePage('FatalError')
                break
              }
            }
          }
          setPreloading(false)
        }, 300)
        return
      }
      setTimeout(() => {
        changePage(page)
        initPage(pages.pop())
      }, 25)
    }

    // todo: set logic
    initPage(pages.pop())
  }, [])

  useEffect(() => {
    if (!banks.data) {
      dispatch(formSlice.actions.fetchBanks())
    }
  }, [])

  useEffect(() => {
    if ((selectedSourceBank || !selectedSourceBankEnabled) && !formData.data) {
      dispatch(formSlice.actions.fetchData())
    }
  }, [selectedSourceBank])

  // routing
  useEffect(() => {
    if (preloading) {
      return
    }
    routing()
  }, [preloading, isPriceChangedAccepted, formData.data, update.data, fatalError, selectedSourceBank])

  return (
    <div style={{
      minHeight: '100svh',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: "border-box",
      position: 'relative',
      zIndex: 1,
      overflowX: 'hidden',
    }}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @font-face {
            font-family: "Montserrat";
            src: url("${Montserrat}") format("truetype");
            font-weight: normal;
            font-style: normal;
          }
          @font-face {
            font-family: "MontserratBold";
            src: url("${Montserrat}") format("truetype");
            font-weight: bold;
            font-style: normal;
          }
          body {
            font-family: 'Montserrat', sans-serif;
            margin: 0;
            background: linear-gradient(360deg, #0A113E 0%, #183E85 100%);
          }
        `
        }}
      />

      <div
        className={`will`}
        style={{zIndex: preloading ? 3 : 0}}
      >
        <Preloading/>
      </div>

      <div className={`${styles.preloading} ${preloading ? null : styles.ready}`}>
        <CSSTransition
          nodeRef={nodeRefSourceBankList}
          in={activePage === 'SourceBankList'}
          timeout={preloading ? 1 : 400}
          classNames="my-node"
        >
          <div
            className={`will ${prevPage === 'SourceBankList' ? 'prev' : null}`}
            ref={nodeRefSourceBankList}
            style={{zIndex: activePage === 'SourceBankList' ? 2 : 1}}
          >
            {(activePage === 'SourceBankList' || prevPage === 'SourceBankList') && (
              <SourceBankList
                isSourceBanks={true}
                title={'JPAY'}
              />
            )}
          </div>
        </CSSTransition>

        <CSSTransition
          nodeRef={nodeRefAmountChanged}
          in={activePage === 'AmountChanged'}
          timeout={preloading ? 1 : 400}
          classNames="my-node"
        >
          <div
            className={`will ${prevPage === 'AmountChanged' ? 'prev' : null}`}
            ref={nodeRefAmountChanged}
            style={{zIndex: activePage === 'AmountChanged' ? 2 : 1}}
          >
            {(activePage === 'AmountChanged' || prevPage === 'AmountChanged') && formData.data && (
              <AmountChanged
                setIsPriceChangedAccepted={setIsPriceChangedAccepted}
              />
            )}
          </div>
        </CSSTransition>

        <CSSTransition
          nodeRef={nodeRefCardForm}
          in={activePage === 'CardForm'}
          timeout={preloading ? 1 : 400}
          classNames="my-node"
        >
          <div
            className={`will ${prevPage === 'CardForm' ? 'prev' : null}`}
            ref={nodeRefCardForm}
            style={{zIndex: activePage === 'CardForm' ? 2 : 1}}
          >
            {(activePage === 'CardForm' || prevPage === 'CardForm') && (
              <CardForm
                title={'JPAY'}
              />
            )}
          </div>
        </CSSTransition>

        <CSSTransition
          nodeRef={nodeRefStatus}
          in={activePage === 'Status'}
          timeout={preloading ? 1 : 400}
          classNames="my-node"
        >
          <div
            className={`will ${prevPage === 'Status' ? 'prev' : null}`}
            ref={nodeRefStatus}
            style={{zIndex: activePage === 'Status' ? 2 : 1}}
          >
            {(activePage === 'Status' || prevPage === 'Status') && update.data && (
              <Status/>
            )}
          </div>
        </CSSTransition>

        <CSSTransition
          nodeRef={nodeRefFatalError}
          in={activePage === 'FatalError'}
          timeout={preloading ? 1 : 400}
          classNames="my-node"
        >
          <div
            className={`will ${prevPage === 'FatalError' ? 'prev' : null}`}
            ref={nodeRefFatalError}
            style={{zIndex: activePage === 'FatalError' ? 2 : 1}}
          >
            {(activePage === 'FatalError' || prevPage === 'FatalError') && fatalError && (
              <FatalError/>
            )}
          </div>
        </CSSTransition>
      </div>
    </div>
  )
}
