export const hu = {
  common: {
    title: {
      phone: 'Készítsen fordítást',
      card: 'Átutalás kártyaszámmal',
      sbp: 'Végezzen átutalást SBP-n keresztül',
      sberpay: 'Végezzen átutalást sberpay-n keresztül',
      walletm10: 'Készítsen fordítást',
      local_banks: 'Készítsen fordítást',
      account: '',
    },
    copied: 'Copied',
  },
  PageHeader: {
    order: 'Rendelés {{order}}',
  },
  BankList: {
    selectBank: 'Válassza ki a bankot',
    selectSourceBank: 'Válassza ki azt a bankot, ahonnan pénzt utal át',
    bankSelectionNotice: 'Legyen óvatos a bank kiválasztásakor. Nem választhat újra bankot.',
  },
  CardForm: {
    amountChangedTitle: 'A kezdeti összeg részletei átmenetileg nem állnak rendelkezésre. Javasoljuk, hogy fizessen ',
    amountChangedText: '{{amount}} {{currency}}',
    amountChangedConfirm: 'Folytatás',
    sbpPhone: 'Címzett száma',
    sbpBank: 'A címzett bankja',
    sbpFio: 'A címzett neve',
    remainingTime: 'Idő maradt:',
    pan: 'Kártyaszám',
    phone: 'Telefonszám',
    accountNumber: 'Számlaszám',
    recipient: 'Befogadó',
    uploadTransactionReceipt: 'Töltse fel a tranzakció nyugtáját',
    attention: 'Figyelem!',
    paymentConfirmed: 'Ellenőrizze a fizetési állapotot',
    cancel: 'Mégse',
    selectYourMobileDevice: 'Válassza ki a készülékét',
    paymentTypes: {
      card: 'Végezzen átutalást a kártyaszámával',
      phone: 'Végezzen átutalást telefonszámon',
      sbp: '',
      walletm10: 'Tegyen átutalást az M10-en keresztül a telefonszámon',
      local_banks: 'Végezzen átutalást a számlaszámával',
      account: 'Végezzen átutalást a számlaszámával',
    },
    paymentTips: {
      doNotChangeTransferAmount: 'Ne változtassa meg az átutalás összegét',
      doNotSpecifyTransferComment: 'Ne fűzzön megjegyzést a fordításhoz',
      ensureRecipientNameAndBankMatchDetails: 'Győződjön meg arról, hogy a címzett neve és bankja megfelel ezeknek az adatoknak',
      paymentMustBeMadeInOneAmount: 'A fizetésnek egy összegben kell megtörténnie',
      pressCancelToSelectAnotherBank: 'Másik bank kiválasztásához kattintson a "Mégse" gombra, és hozza létre újra a fizetést.',
      transferToAccountNumberAbove: 'Tegyen átutalást a fent megadott számlaszámra',
      ifUnableToPayPressCancel: 'Ha nem tudott fizetni, kattintson a "Mégse" gombra',
      enterPaymentAmountAndSubmit: 'A jelentkezésben tüntesse fel a feltöltéskor megadott fizetési összeget, majd kattintson a "Küldés" gombra.',
      card1: 'Transfer the strictly specified amount',
      card2: 'When transferring, check that the card number matches the one specified above',
      card3: 'After the transfer, check the payment status',
      card4: 'If you couldn\'t pay, click the "Cancel" button',
      sberpay1: 'Válassza ki a készülékét',
      sberpay2: 'Olvassa be a QR-kódot',
      sberpay3: 'Az alkalmazásban történő fizetéskor kérjük, adja meg a pontos összeget',
      sberpay4: 'Az átutalás után ellenőrizze a fizetés állapotát',
      sberpay5: 'Ha nem tudott fizetni, kattintson a "Mégse" gombra',
      sberpayMobile1: 'A szigorúan meghatározott összeget utalja át',
      sberpayMobile2: 'Az átutalás után ellenőrizze a fizetés állapotát',
      sberpayMobile3: 'Ha nem tudott fizetni, kattintson a "Mégse" gombra',
      sbp1: 'A szigorúan meghatározott összeget utalja át',
      sbp2: 'Átutaláskor ellenőrizze, hogy a címzett neve és bankja megegyezik-e a fent megadottakkal',
      sbp3: 'Az átutalás után ellenőrizze a fizetés állapotát',
      sbp4: 'Ha nem tudott fizetni, kattintson a "Mégse" gombra',
    },
  },
  CashDeliveryForm: {
    enterCourierData: 'Adja meg a futár adatait',
    saveConfirmationCode: 'Mentse el az ellenőrző kódot',
    confirmationRequiredForCourier: 'A futárral való találkozáskor lesz szükség rá',
    confirmationCodePrompt: 'Megerősítő kód:',
    attentionWarning: 'Légy óvatos!',
    courierContactUsageNotice: 'A megadott adatokat a futár az Önnel való kapcsolatfelvételhez használja fel',
    orderAcceptedMessage: 'Köszönjük, megrendelését elfogadtuk.',
    courierConfirmationMessage: 'Kérjük, várja meg a futár üzenetét a megerősítéshez.',
    sendCourier: 'Küldj futárt',
    label: {
      messenger: 'Hírnök',
      phone: 'Telefonszám',
      login: 'Távirat bejelentkezés/telefonszám',
      address: 'A futárral való találkozás címe',
      immediateDelivery: 'Azonnal szállítani',
      deliveryDateTime: 'Szállítás dátuma és időpontja',
    },
    error: {
      required: 'Kötelező mező',
      incorrectNumber: 'Helytelen szám',
      incorrectLogin: 'Hibás bejelentkezés',
    },
  },
  StatusBlock: {
    paymentSuccessful: 'Átutalás megérkezett',
    paymentDeclined: 'A fordítás nem található',
    returnToShop: 'Vissza a webhelyre',
    status: {
      somethingWentWrong: 'Valami elromlott.',
      waitingForFunds: 'Várjuk a pénz megérkezését.',
      operationCancelled: 'A műveletet törölték.',
      paymentTimeExpired: 'A fizetési idő lejárt.',
    },
    return: {
      returnToShopAndRetryPayment: 'Visszatérhet az oldalra, és megismételheti a fizetést.',
      returnToShop: 'Visszatérhet az oldalra.',
    },
  },
  Upload: {
    ok: 'Ok',
    upload: 'Letölteni',
    error: 'Hiba',
  },
  FatalError: {
    somethingWentWrong: 'Valami elromlott.',
    returnToShopAndRetryPayment: 'Visszatérhet az oldalra, és megismételheti a fizetést.',
    returnToShop: 'Vissza a webhelyre',
    returnToPaymentMethodSelection: 'Térjen vissza a fizetési mód kiválasztásához',
    cancel: 'Mégse',
    code: {
      NO_TERMINALS_AVAILABLE: 'Ehhez a fizetési módhoz nem állnak rendelkezésre terminálok.',
      FORM_PAY_ALREADY_SUBMITED: 'Kérjük, térjen vissza az üzletbe az újrafizetéshez.',
      COMMON_ERROR: 'Hiba a fizetés során.',
      DUPLICATE_ORDER_ERROR: 'Már létezik megrendelés ezzel a számmal.',
      ROUTE_ERROR: 'Erre a fizetésre nincs lehetőség.',
    },
  }
}
