import React from 'react'
import styles from './amount.module.scss'
import coin from './coin.png'
import {IconButton} from '@mui/material'
import {copyToClipboard} from '../../../../utils/copyToClipboard'
import copy from '../Requisites/copy.svg'
import {getCurrencySymbol} from '../../../../config/currency';
import {useSelector} from 'react-redux';

export function Amount(props) {
  const {params, formInfo} = useSelector(store => store.form)
  const {canCopy} = props
  const amount = formInfo.amountFormatted

  return (
    <div className={styles.container}>
      <div
        className={styles.copyBtnIcon}
        style={{
          backgroundImage: `url("${coin}")`
        }}
      />

      <div className={styles.amount}>
        {amount} {getCurrencySymbol(params.currency)}
      </div>

      {canCopy && (
        <IconButton
          className={styles.copyBtn}
          onClick={() => {
            copyToClipboard(props.amount)
          }}
        >
          <div
            className={styles.copyBtnIcon}
            style={{
              backgroundImage: `url("${copy}")`,
            }}
          />
        </IconButton>
      )}
    </div>
  )
}
