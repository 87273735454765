export const mobileUtils = {

  isAndroidMobile() {
    return /Android/i.test(navigator.userAgent);
  },

  isAppleMobile() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent) || ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)
  },

  isMobile() {
    return /iPhone|Android/i.test(navigator.userAgent) || ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)
  },

  getMobileOS() {
    if (!mobileUtils.isMobile()) {
      return 'desktop'
    }
    if (mobileUtils.isAppleMobile()) {
      return 'ios'
    }
    if (mobileUtils.isAndroidMobile()) {
      return 'android'
    }
    return 'android'
  }
}
