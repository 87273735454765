import sberpayLogo from './sberpayLogo.svg';
import tpayLogo from './tpayLogo.svg';
import {Typography} from '@mui/material';
import React from 'react';
import {LoadingButton} from '@mui/lab';

export const deepLinksButtons = {
  sberpay: () => {
    return (
      <div style={{zIndex: 10, display: 'flex', gap: 12, alignItems: 'center'}}>
        <div
          style={{
            background: `url("${sberpayLogo}") no-repeat center center`,
            height: 32,
            width: 32,
            backgroundSize: 'contain',
          }}
        />
        <Typography style={{color: '#fff'}}>
          sberpay
        </Typography>
      </div>
    )
  },
  tpay: () => {
    return (
      <div style={{zIndex: 10, display: 'flex', gap: 12, alignItems: 'center'}}>
        <div
          style={{
            background: `url("${tpayLogo}") no-repeat center center`,
            height: 64,
            width: 64,
            backgroundSize: 'contain',
          }}
        />
      </div>
    )
  }
}
