import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Tooltip,
  Typography
} from '@mui/material'
import {useSelector} from 'react-redux'
import {getBankInfo} from '../../config/bankInfo'
import React, {useCallback, useEffect, useState} from 'react'
import {dispatch} from '../../store/store'
import {formSlice} from '../../store/form/formSlice'
import {copyToClipboard} from '../../utils/copyToClipboard'
import {IconButton} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {getRequisiteType} from '../../config/paymentTypes'
import {apiURL} from '../../config/constants'
import {openReplay} from '../../externals/openreplay'
import {sbpShortLogo} from '../../assets/banks/sbp/sbpImages'
import './cardForm.css'

import {getMobileOperatingSystem} from '../../utils/getMobileOperatingSystem'
import {useTranslation} from "react-i18next"
import {QRCode} from "../QRCode/QRCode"
import {PageHeader} from "../PageHeader/PageHeader"
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {getCurrencySymbol} from "../../config/currency";
import {deepLinksButtons} from '../deepLinksButtons/deepLinksButtons';
import {mobileUtils} from '../../utils/mobileUtils';
import {LoadingButton} from '@mui/lab';
import {getDeepLinks} from '../../config/deepLinks';
import {sleep} from '../../utils/sleep';
import {Upload} from '../Upload/Upload';

async function upload({invoice_id, file}) {
  const formData = new FormData()
  formData.append("file", file.file)
  formData.append("invoice_id", invoice_id)

  try {
    const response = await fetch(`${apiURL}/p2p-selector/screenshot`, {
      method: 'POST',
      body: formData
    });
    const json = await response.json()
    if (json.status === 'success') {
      return {status: 'success'}
    }
    return {status: 'error'}
  } catch (e) {
    return {status: 'error'}
  }
}

export function CardForm() {
  const {t, i18n} = useTranslation('translation', {keyPrefix: 'CardForm'})
  const {t: ct} = useTranslation('translation', {keyPrefix: 'common'})
  const form = useSelector(store => store.form)
  const {formData, formTime, params} = form
  const [isPriceChangedModal, setIsPriceChangedModal] = useState(true)

  const [isMobile] = useState(mobileUtils.isMobile())

  const [isDeepLinksProcessing, setIsDeepLinksProcessing] = useState(false)
  const startProcessing = useCallback(async () => {
    setIsDeepLinksProcessing(true)
    const {beneficiary} = formData?.data
    const links = getDeepLinks(
      mobileUtils.getMobileOS(),
      form.paymentWidgetData.display_type,
      beneficiary.requisite_type,
      {
        ...beneficiary,
        amount: Number(form.formInfo.amount)
      },
    )
    localStorage.setItem(`DeepRedirectData-${params.invoice_id}`, JSON.stringify({
      invoice_id: params.invoice_id,
      isFinished: false,
      isFound: false,
      isConfirmed: false,
      isFailToPay: false,
      currentLinkIndex: 0,
      links,
    }))

    window.location = window.location.href
  }, [formData])

  /* deep redirect   */
  const SberpayRedirectRawData = localStorage.getItem(`DeepRedirectData-${params.invoice_id}`)
  const SberpayRedirectData = JSON.parse(SberpayRedirectRawData)
  const links = SberpayRedirectData?.links
  const currentLinkIndex = SberpayRedirectData?.currentLinkIndex

  useEffect(() => {
    void async function () {
      if (links && links[currentLinkIndex]) {
        let found = false
        const deepLink = links[currentLinkIndex]

        window.focus()

        function onBlur(e) {
          // found = true
        }

        window.addEventListener("blur", onBlur);

        await sleep(25)

        setTimeout(() => {
          window.location = deepLink.link
        })

        await sleep(500)

        window.removeEventListener("blur", onBlur)

        if (found) {
          localStorage.setItem(`DeepRedirectData-${params.invoice_id}`, JSON.stringify({
            ...SberpayRedirectData,
            isFinished: true,
            isFound: true,
            currentLinkIndex: currentLinkIndex,
          }))
        } else {
          localStorage.setItem(`DeepRedirectData-${params.invoice_id}`, JSON.stringify({
            ...SberpayRedirectData,
            isFinished: false,
            currentLinkIndex: currentLinkIndex + 1,
          }))
        }
        window.location = window.location.href
      } else {
        if (SberpayRedirectData && !SberpayRedirectData.isFinished) {
          localStorage.setItem(`DeepRedirectData-${params.invoice_id}`, JSON.stringify({
            ...SberpayRedirectData,
            isFinished: true,
          }))
        }
      }
    }()
  }, [links, currentLinkIndex])
  /*   */

  const languageTag = i18n.language === 'ru' ? 'ru' : 'en'

  const {banks} = form
  const widgetData = banks?.data?.payment_widget_data ?? {}

  useEffect(() => {
    if (!formData.data) {
      dispatch(formSlice.actions.fetchData())
    }
  }, [])

  const [toolTipsState, setToolTipsState] = React.useState({
    account_number: {
      isOpen: false,
      timeout: null,
    },
    pan: {
      isOpen: false,
      timeout: null,
    },
    name: {
      isOpen: false,
      timeout: null,
    },
    phone: {
      isOpen: false,
      timeout: null,
    }
  });
  const handleTooltipClose = (key) => {
    clearTimeout(toolTipsState[key].timeout)
    setToolTipsState({
      ...toolTipsState,
      [key]: {
        isOpen: false,
        timeout: null,
      }
    })
  };
  const handleTooltipOpen = (key) => {
    clearTimeout(toolTipsState[key].timeout)
    setToolTipsState({
      ...toolTipsState,
      [key]: {
        isOpen: true,
        timeout: setTimeout(() => {
          handleTooltipClose(key)
        }, 500)
      }
    })
  };

  const timeLimit = formData?.data?.ttl * 60 - (((Date.now() - formTime) / 1e3) | 0)
  const [time, setTime] = useState(timeLimit)

  const timeMin = String(Math.floor(Math.round(time) / 60)).padStart(2, '0')
  const timeSec = String(Math.round(time) % 60).padStart(2, '0')

  useEffect(() => {
    if (!timeLimit) {
      return
    }
    const started = Date.now()
    setTime(timeLimit - (Date.now() - started) / 1e3)
    const interval = setInterval(() => {
      setTime(timeLimit - (Date.now() - started) / 1e3)
    }, 1e3)
    return () => clearInterval(interval);
  }, [timeLimit]);

  useEffect(() => {
    if (time <= 0) {
      dispatch(formSlice.actions.formTimeout())
    }
  }, [time])

  const [files, setFiles] = useState(form.files.map(file => {
    const newFile = {...file}
    if (newFile.status === 'pending') {
      newFile.status = 'error'
    }
    return newFile
  }))

  const onFile = useCallback(async (file) => {
    const newFile = {
      key: `file-${files.length}`,
      status: 'pending',
      file,
    }
    setFiles((files) => [...files, newFile])
    const {status} = await upload({invoice_id: params.invoice_id, file: newFile})
    newFile.status = status
    setFiles((files) => [...files])
  }, [files, params.invoice_id])

  useEffect(() => {
    dispatch(formSlice.actions.setFiles(files.map(file => ({key: file.key, status: file.status}))))
    dispatch(formSlice.actions.saveInCache())
  }, [files])

  if (formData.loading || !formData.data || (Number.isInteger(time) && time < 0)) {
    return (
      <div
        style={{
          flexGrow: 1,
          marginTop: 80,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress
          size={120}
          thickness={2}
        />
        <Typography mt={6} style={{fontSize: 14, textAlign: 'center'}} >
          {widgetData.loader_description ? widgetData.loader_description : t('loaderDescription')}
        </Typography>
      </div>
    )
  }

  const {beneficiary} = formData.data

  const bankInfo = getBankInfo(beneficiary.bank_name)

  console.log('bankInfo', bankInfo)

  let {
    type: paymentType,
    displayType,
    displayTypeTitleModified,
    helpTexts,
    helperTextsMobile,
    deepLinkType,
  } = getRequisiteType(beneficiary, params, form)

  let instructions = widgetData.instructions ? Object.values(widgetData.instructions) : helperTextsMobile
  let instructionsDesktop = widgetData.instructions_desktop ? Object.values(widgetData.instructions_desktop) : helpTexts

  let cardBlockShow = openReplay.getFlag('show-form') ?? true

  const amount = form.formInfo.amountFormatted

  const showQrCode = !isMobile && deepLinkType
  const DeepLinkButton = deepLinksButtons[deepLinkType]

  const isProofRequired = formData.data.proof_required
  const isProofLoaded = files.some(file => file.status === 'success')

  return (
    <div>
      {formData?.data?.is_amount_changed && isPriceChangedModal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            background: '#fff',
            minHeight: '100vh',
            maxWidth: 432,
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            padding: 32,
            boxSizing: "border-box"
          }}
        >
          <Typography mb={0} variant="h5">
            {t('amountChangedTitle')}
          </Typography>

          {t('amountChangedSubTitle1') && (
            <Typography mb={2} variant="subtitle1">
              {t('amountChangedSubTitle1')}
            </Typography>
          )}

          {t('amountChangedSubTitle2') && (
            <Typography mb={2} variant="subtitle1">
              {t('amountChangedSubTitle2')}
            </Typography>
          )}

          <Typography variant="h4">
            {t('amountChangedText', {amount, currency: getCurrencySymbol(params.currency)})}
          </Typography>

          <Button
            variant="contained"
            fullWidth size="large"
            sx={{mb: 2.5, mt: 4}}
            onClick={() => setIsPriceChangedModal(false)}
          >
            {t('amountChangedConfirm')}
          </Button>

          <Button
            variant="outlined"
            fullWidth size="large"
            onClick={() => {
              dispatch(formSlice.actions.update({status: 'cancelled'}))
            }}
          >
            {t('cancel')}
          </Button>
        </div>
      )}

      <div style={{display: 'flex', alignItems: 'center'}}>
        {/*{displayType === 'sbp' && (*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      background: `url("${sbpShortLogo}") no-repeat center center`,*/}
        {/*      height: 48,*/}
        {/*      width: 48,*/}
        {/*      marginRight: 12,*/}
        {/*      backgroundSize: 'contain',*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        <Typography variant="h5">
          {ct(`title.${displayTypeTitleModified ?? displayType}`)}
        </Typography>
      </div>

      <PageHeader/>

      {showQrCode && (
        <Card sx={{boxShadow: 6}} style={{borderRadius: 16}} className={'qrCodeWrapperDefault'}>
          <QRCode
            data={window.location.href}
            icon={null}
            width={336}
            height={336}
          />
        </Card>
      )}

      {!showQrCode && deepLinkType && (
        <LoadingButton
          sx={{mb: 3.5, mt: 1.5}}
          className={`${deepLinkType}Btn`}
          loading={isDeepLinksProcessing}
          onClick={startProcessing}
        >
          <DeepLinkButton/>
        </LoadingButton>
      )}

      {displayType === 'sbp' && (
        <>
          <Typography mt={1} style={{fontSize: 14}}>
            {t('sbpPhone')}
          </Typography>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography style={{fontSize: 20, fontWeight: 600}}>
              {beneficiary.phone}
            </Typography>
            <ClickAwayListener onClickAway={() => handleTooltipClose('phone')}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => handleTooltipClose('phone')}
                open={toolTipsState.phone.isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={ct('copied')}
              >
                <IconButton
                  style={{marginTop: -6}}
                  onClick={() => {
                    copyToClipboard(beneficiary.phone)
                    handleTooltipOpen('phone')
                  }}
                >
                  <ContentCopyIcon color="primary"/>
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          </div>

          <Typography mt={1} style={{fontSize: 14}}>
            {t('sbpBank')}
          </Typography>
          <div style={{height: 34}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div
                style={{
                  background: `url("${bankInfo.shortLogo}") no-repeat center center`,
                  height: 24,
                  width: 24,
                  marginRight: 6,
                  backgroundSize: 'contain',
                }}
              />
              <Typography style={{fontSize: 20, fontWeight: 600}}>
                {bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)}
              </Typography>
            </div>
          </div>
          <Typography mt={1} style={{fontSize: 14}}>
            {t('sbpFio')}
          </Typography>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography style={{fontSize: 20, fontWeight: 600}}>
              {beneficiary.name}
            </Typography>
            <ClickAwayListener onClickAway={() => handleTooltipClose('name')}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => handleTooltipClose('name')}
                open={toolTipsState.name.isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={ct('copied')}
              >
                <IconButton
                  style={{marginTop: -6}}
                  onClick={() => {
                    copyToClipboard(beneficiary.name)
                    handleTooltipOpen('name')
                  }}
                >
                  <ContentCopyIcon color="primary"/>
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          </div>
        </>
      )}

      {t(`paymentTypes.${displayTypeTitleModified ?? displayType}`) && (
        <Typography mb={3} style={{fontSize: 24}} className={"title"}>
          {t(`paymentTypes.${displayTypeTitleModified ?? displayType}`)}
        </Typography>
      )}

      {cardBlockShow && displayType !== 'sbp' && (
        <Card sx={{boxShadow: 6}} style={{borderRadius: 16}}>
          <CardContent>
            {paymentType === 'sbp' ? (
              <Box mb={2} style={{display: 'flex', alignItems: 'center'}}>
                {bankInfo.shortLogo && (
                  <div
                    style={{
                      background: `url("${sbpShortLogo}") no-repeat center center`,
                      height: 40,
                      width: 40,
                      marginRight: 12,
                      backgroundSize: 'contain',
                    }}
                  />
                )}
                <Typography style={{fontSize: 20, fontWeight: 600}}>
                  {bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)}
                </Typography>
              </Box>
            ) : (
              <Box mb={2} style={{display: 'flex', alignItems: 'center'}}>
                {bankInfo.shortLogo && (
                  <div
                    style={{
                      background: `url("${bankInfo.shortLogo}") no-repeat center center`,
                      height: 40,
                      width: 40,
                      marginRight: 12,
                      backgroundSize: 'contain',
                    }}
                  />
                )}
                <Typography style={{fontSize: 20, fontWeight: 600}}>
                  {bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)}
                </Typography>
              </Box>
            )}

            {paymentType === 'card' && (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('pan')}
                </Typography>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontSize: 20, fontWeight: 600}}>
                    {beneficiary.pan.replaceAll(' ', '').match(/.{1,4}/g).map((str, i) => {
                      return <span key={`key-${i}`} style={{marginRight: 8}}>{str}</span>
                    })}
                  </Typography>
                  <ClickAwayListener onClickAway={() => handleTooltipClose('pan')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('pan')}
                      open={toolTipsState.pan.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.pan.replaceAll(' ', ''))
                          handleTooltipOpen('pan')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            )}

            {paymentType === 'phone' && (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('phone')}
                </Typography>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontSize: 20, fontWeight: 600}}>
                    {beneficiary.phone}
                  </Typography>
                  <ClickAwayListener onClickAway={() => handleTooltipClose('phone')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('phone')}
                      open={toolTipsState.phone.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.phone)
                          handleTooltipOpen('phone')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            )}

            {paymentType === 'sbp' && (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('phone')}
                </Typography>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontSize: 20, fontWeight: 600}}>
                    {beneficiary.phone}
                  </Typography>
                  <ClickAwayListener onClickAway={() => handleTooltipClose('phone')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('phone')}
                      open={toolTipsState.phone.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.name)
                          handleTooltipOpen('phone')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            )}

            {paymentType === 'local_banks' && (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('accountNumber')}
                </Typography>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontSize: 20, fontWeight: 600}}>
                    {beneficiary.account_number}
                  </Typography>
                  <ClickAwayListener onClickAway={() => handleTooltipClose('account_number')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('account_number')}
                      open={toolTipsState.account_number.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.account_number)
                          handleTooltipOpen('account_number')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            )}

            {paymentType === 'account' && (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('accountNumber')}
                </Typography>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography
                    style={{fontSize: 18, fontWeight: 600, wordWrap: 'break-word', maxWidth: `calc(100% - 44px)`}}>
                    {beneficiary.account_number}
                  </Typography>

                  <ClickAwayListener onClickAway={() => handleTooltipClose('account_number')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('account_number')}
                      open={toolTipsState.account_number.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.account_number)
                          handleTooltipOpen('account_number')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            )}

            {beneficiary.name ? (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('recipient')}
                </Typography>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontSize: 20, fontWeight: 600}}>
                    {beneficiary.name}
                  </Typography>
                  <ClickAwayListener onClickAway={() => handleTooltipClose('name')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('name')}
                      open={toolTipsState.name.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.name)
                          handleTooltipOpen('name')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            ) : (
              <Box mt={4}></Box>
            )}
          </CardContent>
        </Card>
      )}

      <Box mt={2} style={{color: '#666666'}}>
        {t('remainingTime')}
        {' '}
        <Typography component="span" style={{fontSize: 14, color: '#000'}}>
          {timeMin}:{timeSec}
        </Typography>
      </Box>

      <Box mt={2}>
        <Alert severity="null" style={{padding: 0, display: 'block', width: '100%'}}>

          <AlertTitle>
            {t('attention')}
          </AlertTitle>

          <Typography component="span" color="error" style={{fontSize: 16, color: '#666666'}}>
                <span className={'list'}>
                  {getMobileOperatingSystem() && instructions ? (
                    <>
                      {instructions.map((tip, index) => {
                        if (`CardForm.paymentTips.${tip}` === t(`paymentTips.${tip}`)) {
                          return (
                            <span key={`key-${index}`} data-number={`${index + 1}.`}>
                              {tip}
                            </span>
                          )
                        }
                        return (
                          <span key={`key-${index}`} data-number={`${index + 1}.`}>
                            {t(`paymentTips.${tip}`)}
                          </span>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {instructionsDesktop.map((tip, index) => {
                        if (`CardForm.paymentTips.${tip}` === t(`paymentTips.${tip}`)) {
                          return (
                            <span key={`key-${index}`} data-number={`${index + 1}.`}>
                              {tip}
                            </span>
                          )
                        }
                        return (
                          <span key={`key-${index}`} data-number={`${index + 1}.`}>
                            {t(`paymentTips.${tip}`, {})}
                          </span>
                        )
                      })}
                    </>
                  )}
                </span>
          </Typography>
        </Alert>
      </Box>

      {isProofRequired && (
        <Box mt={2.5}>
          <Upload
            files={files}
            onChange={onFile}
          />
        </Box>
      )}

      <Button
        onClick={() => dispatch(formSlice.actions.update({status: 'paid'}))}
        sx={{mb: 2.5, mt: 4}}
        fullWidth
        variant="contained"
        size="large"
        disabled={isProofRequired && !isProofLoaded}
      >
        {t('paymentConfirmed')}
      </Button>

      <Button
        variant="outlined"
        onClick={() => dispatch(formSlice.actions.update({status: 'cancelled'}))}
        fullWidth size="large"
      >
        {t('cancel')}
      </Button>
    </div>
  )
}
